import { ReactElement } from "react";
import { CategoryItem } from ".";
import UpdatedDashboardWidget from "../Dashboard/UpdatedDashboardWidget";
import { Box, Typography, styled } from "@mui/material";
import colors from "../../colors";
import { useNavigate } from "react-router-dom";

const ListItemContainer = styled(Box, {label: "ListItemContainer"})({
  backgroundColor: colors.white900,
  padding: 8,
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: colors.greyBackdrop
  }
});


interface CategoryItemProps {
  categoryItem: CategoryItem;
  itemIndex: number;
}

const CategoryListItem = ({categoryItem, itemIndex}: CategoryItemProps): ReactElement => {
  const navigate = useNavigate();

  return (
    <ListItemContainer marginTop={itemIndex === 0 ? 0 : '1px'} 
      onClick={() => navigate(categoryItem.href)} sx={{cursor: 'pointer'}}>
      <Box display='flex' gap={2} alignItems={'center'}>
        <Box display='flex' width={40} height={40} justifyContent='center' alignItems='center'>
          <Box component={'img'} src={categoryItem.icon_url} maxHeight={35} maxWidth={35} />
        </Box>
        <Typography variant='body1' fontWeight={600}>{categoryItem.name}</Typography>
      </Box>
    </ListItemContainer>
  );
};

interface NotesWidgetProps {
  categories: CategoryItem[];
  onClickAddCategory?: () => void;
  loading: boolean;
  locked: boolean;
}

const NotesWidget = ({
  categories, onClickAddCategory, loading, locked
}: NotesWidgetProps): ReactElement => {

  return (
    <UpdatedDashboardWidget
      title="Notes"
      buttonName="Create New"
      buttonOnClick={onClickAddCategory}
      isLoading={loading}
      children={
        <Box hidden={loading}>
          {
            categories.filter(cat => cat.visible).map((category, i) => 
              <CategoryListItem key={category.href} categoryItem={category} itemIndex={i}/>)
          }
        </Box>
      }
      allLoaded={true}
      accessBlocked={locked}
      upgradeMessage={'Upgrade your subscription plan to unlock Profile Notes'}
      borderColorOverride={colors.black200}
    />
  );
};

export default NotesWidget;