import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import ExecutiveProfile from "./ExecutiveProfile";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GlobalModalComponentName, MeetingFilter, RootState, ThunkDispatchType, actions } from "../../store";
import { EXEC_SUBPAGE, PAGE_URL } from "../../constants";
import { CABINET_ICONS, EMOJIS } from "../../icons";
import EditLeaderBasic from "../../components/InfoCard/LeaderCard/EditLeaderBasic";
import ShareLeaderContainer from "../../components/Sharing";
import EditProfileCategory from "../../components/ProfileNotes/EditProfileCategory";

type RouteParams = { leaderId: string; };

export interface CategoryItem {
  name: string;
  icon: string;
  icon_url?: string;
  href: string;
  visible?: boolean;
}

const ExecutiveProfileContainer = (): ReactElement => {
  const params = useParams<RouteParams>();
  const leaderId = params.leaderId ?? undefined;

  const leaders = useSelector((state: RootState) => state.leaders);
  const profileNotes = useSelector((state: RootState) =>  state.profileNotes);
  const features = useSelector((state: RootState) =>  state.auth.user?.features);
  const meetingsLoaded = useSelector((root: RootState) => root.schedule.meetingsLoaded);

  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();

  const fetchProfileCategories = useCallback((leader: number) => 
    dispatch(actions.profileNotes.fetchProfileCategories(leader)), [dispatch]);
  const fetchProfileNotes = useCallback((leader: number) => 
    dispatch(actions.profileNotes.fetchProfileNotes(leader)), [dispatch]);
  const openGlobalModal = (componentName: GlobalModalComponentName) => 
    dispatch(actions.globalModal.openModal(componentName));
  const startPollingMeetings = useCallback((query: Partial<MeetingFilter>) => 
    dispatch(actions.schedule.startPollingMeetings(query)), [dispatch]);

  const [editOpen, setEditOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);

  const currentLeader = useMemo(() => {
    let newCurrentLeader = null;
    if (leaders.loaded) {
      if (leaderId) {
        newCurrentLeader = leaders.leaders.find(leader => leader.id.toString() === leaderId);
      }
      if (!newCurrentLeader) {
        if (leaders.leaders.length > 0) {
          newCurrentLeader = leaders.leaders[0];
        } else {
          navigate(PAGE_URL.DASHBOARD);
        }
      }
    }
    return newCurrentLeader;
  }, [leaders.loaded, leaders.leaders, leaderId, navigate]);

  useEffect(() => {
    if (currentLeader) {
      fetchProfileCategories(currentLeader.id);
      fetchProfileNotes(currentLeader.id);
    }
  }, [currentLeader, fetchProfileCategories, fetchProfileNotes]);

  useEffect(() => {
    let stop = () => {
      return;
    };
    (async () => {
      stop = await startPollingMeetings({status: 1});
    })();
    return () => {
      stop();
    };
  }, [startPollingMeetings]);

  const categories: CategoryItem[] = [
    {name: 'Airlines', icon: EMOJIS.AIRLINES, icon_url: CABINET_ICONS.PLANE.icon_url,  
      href: `${PAGE_URL.EXECUTIVE}/${currentLeader?.id}/${EXEC_SUBPAGE.AIRLINES}`, 
      visible: currentLeader?.permissions.standard.airlines.view}, 
    {name: 'Hotels', icon: EMOJIS.HOTELS, icon_url: CABINET_ICONS.HOTEL.icon_url, 
      href: `${PAGE_URL.EXECUTIVE}/${currentLeader?.id}/${EXEC_SUBPAGE.HOTELS}`, 
      visible: currentLeader?.permissions.standard.hotels.view},
    {name: 'Family', icon: EMOJIS.PERSONAL, icon_url: CABINET_ICONS.FAMILY.icon_url,
      href: `${PAGE_URL.EXECUTIVE}/${currentLeader?.id}/${EXEC_SUBPAGE.PERSONAL}`, 
      visible: currentLeader?.permissions.standard.personal.view},
  ];

  let customCategories: CategoryItem[] = Object.values(profileNotes.categories).sort((a, b) => a.order - b.order)
    .map(category => ({
      name: category.title, 
      icon: category.icon, 
      icon_url: category.icon_url,
      href: `${PAGE_URL.EXECUTIVE}/${currentLeader?.id}/${EXEC_SUBPAGE.PROFILE_CATEGORIES}/${category.id}`, 
      visible: true,
    }));

  if (!features?.PROFILES) {
    customCategories = customCategories.slice(0, 2);
  }

  const handleClickEdit = () => {
    setEditOpen(true);
  };

  const handleClickShare = () => {
    if (features?.COLLABORATION) {
      setShareOpen(true);
    } else {
      openGlobalModal(GlobalModalComponentName.CABINET_PROMO);
    }
  };

  const handleClickAddCategory = () => {
    if (!currentLeader?.is_shared) {
      setAddCategoryOpen(true);
    } else {
      setAddCategoryOpen(false);
    }
  };

  if (currentLeader) {
    return (
      <>
        <ExecutiveProfile
          leader={currentLeader}
          categories={categories}
          customCategories={customCategories}
          onClickEdit={handleClickEdit}
          onClickShare={handleClickShare}
          onClickAddCategory={handleClickAddCategory}
          notesLocked={!features?.PROFILES}
          meetingsLoaded={meetingsLoaded}
          categoriesLoaded={profileNotes.categoriesLoaded}
        />
        <EditLeaderBasic 
          leader={currentLeader} 
          isOpen={editOpen}
          onDidDismiss={() => setEditOpen(false)}
        />
        <ShareLeaderContainer 
          leader={currentLeader} 
          isOpen={shareOpen} 
          onDidDismiss={() => setShareOpen(false)}
        />
        <EditProfileCategory
          leader={currentLeader}
          isOpen={addCategoryOpen}
          onDidDismiss={() => setAddCategoryOpen(false)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default ExecutiveProfileContainer;