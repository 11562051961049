import { DateTime } from 'luxon';
import { ReactElement, useState } from 'react';
import { EVENT_TYPE, MEETING_STATUS_LABELS } from '../../../constants';
import { actions, Meetings, MeetingSlot, MeetingUpdate, RootState, ThunkDispatchType } from '../../../store';
import { trackEventWithExtra } from '../../../utils/appAnalyticsUtils';
import { useDispatch, useSelector } from 'react-redux';
import LeaderRecurringMeetingsWidget from './LeaderRecurringMeetingsWidget';

interface Props {
  meetingsLoaded: boolean;
  leaderId: number;
}

const LeaderRecurringMeetingsWidgetContainer = ({
  meetingsLoaded, leaderId
}: Props): ReactElement => {

  const meetings = useSelector((state: RootState) =>  state.schedule.meetings);
  const dispatch = useDispatch<ThunkDispatchType>();
  const updateMeeting = (
    meeting: MeetingUpdate, timeSlots: MeetingSlot[], files?: File[] | undefined
  ) => dispatch(actions.schedule.updateMeeting(meeting, timeSlots, files));

  // This state is only to fix a bug with ionic's onChange handler for selects
  //   The bug is that the select triggers onChange events too many times and for
  //   undetermined reasons (e.g. state changes that don't even effect the select)
  const [statusChangePending, setStatusChangePending] = useState<{[meetingId: number]: number}>({});

  const handleUpdateStatus = async (id: number, status: number) => {
    const date_scheduled = status !== 1 ? DateTime.utc().toString() : null;
    // Required to counter an Ionic bug. See comment above where statusChangePending is defined
    if (statusChangePending[id] !== status) {
      setStatusChangePending(prev => ({...prev, [id]: status}));
      await updateMeeting({ id, date_scheduled, status }, []);
      setStatusChangePending(prev => ({...prev, [id]: -1}));
      trackEventWithExtra({
        eventName: EVENT_TYPE.SCHEDULING_CHANGE_STATUS, 
        extra: { status: MEETING_STATUS_LABELS[status] },
      });
    }
  };

  const reuseableMeetings : Meetings = {};
  Object.values(meetings).forEach(meeting => {
    if (meeting.is_reusable) {
      reuseableMeetings[meeting.id] = meeting;
    }
  });

  const filteredMeetings : Meetings = {};
  Object.values(reuseableMeetings).forEach(meeting => {
    if (meeting.leaders.includes(leaderId)) {
      filteredMeetings[meeting.id] = meeting;
    }
  });

  return (
    <LeaderRecurringMeetingsWidget
      meetings={filteredMeetings}
      meetingsLoaded={meetingsLoaded}
      onUpdateStatus={handleUpdateStatus}
    />
  );
};

export default LeaderRecurringMeetingsWidgetContainer;