import { ReactElement, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { PAGE_URL } from '../../../constants';
import { Meeting, Meetings } from '../../../store';
import { sortMeetingLogs } from '../../../utils/scheduleUtils';
import colors from '../../../colors';
import MeetingListItem from '../../Dashboard/MeetingListItem';
import UpdatedDashboardWidget from '../../Dashboard/UpdatedDashboardWidget';


interface Props {
  meetings: Meetings;
  meetingsLoaded: boolean;
  onUpdateStatus: (id: number, status: number) => Promise<void>;
}

interface MeetingProps {
  meeting: Meeting;
}

export const LeaderRecurringMeetingsWidget = ({
  meetings, meetingsLoaded, onUpdateStatus
}: Props): ReactElement => {
  const sortedMeetings = useMemo(() => {
    return Object.keys(meetings).sort(sortMeetingLogs(meetings));
  }, [meetings]);


  const MeetingWidgetItem = ({meeting}: MeetingProps): ReactElement => {
    if (meeting?.leaders) { // shouldn't really be necessary but we've encountered errors when navigating quickly

      return (
        <MeetingListItem
          meeting={meeting}
          key={meeting.id}
          onUpdateStatus={onUpdateStatus}
          navigateTo={`${PAGE_URL.SCHEDULE}/${meeting.id}/`}
          titleSx={{fontWeight: 500, marginBottom: '4px'}}
          containerSx={{paddingLeft: 0, marginBottom: '1px'}}
          hideIcon
          subtitle={
            <Box>
              <Typography variant='body2' fontSize={12} color={colors.black700}>
                <strong>Reusable:</strong>  available for booking
              </Typography>
            </Box>
          }
        />
      );
    } else {
      return <></>;
    }
  };

  return (
    <UpdatedDashboardWidget
      title={'Reusable Meetings'}
      isLoading={!meetingsLoaded}
      isEmpty={sortedMeetings.length === 0 && meetingsLoaded}
      emptyMessage={'This team member has no pending Recurring Meetings'}
      allLoaded={meetingsLoaded}
      borderColorOverride={colors.black200}
      children={
        <Box>
          {
            sortedMeetings && (
              sortedMeetings.map((meetingId) => 
                <MeetingWidgetItem key={meetingId} meeting={meetings[meetingId]}/>)
            )
          }
        </Box>
      }
    />
  );
};
  
export default LeaderRecurringMeetingsWidget;