import { ReactElement } from "react";
import CabinetPage from "../../components/Common/CabinetPage";
import { Box, Grid, Typography } from "@mui/material";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { Leader } from "../../store";
import { CategoryItem } from ".";
import ExecutiveProfileHeader from "./ExecutiveProfileHeader";
import NotesWidget from "./NotesWidget";
import { LeaderCard } from "../../components/InfoCard/LeaderCard/LeaderCard";
import LeaderMeetingsWidget from "./LeaderMeetingsWidget";
import LeaderRecurringMeetingsWidget from "./LeaderRecurringMeetingsWidget";
import { IoShareSocial, IoCreateOutline } from "react-icons/io5";


export interface ExecutiveProfileProps {
  leader: Leader;
  categories: CategoryItem[];
  customCategories?: CategoryItem[];
  onClickEdit: () => void;
  onClickShare: () => void;
  onClickAddCategory: () => void;
  notesLocked: boolean;
  meetingsLoaded: boolean;
  categoriesLoaded: boolean;
}

export const ExecutiveProfile = ({
  leader, categories, customCategories, onClickEdit, onClickShare, onClickAddCategory, notesLocked, meetingsLoaded,
  categoriesLoaded
}: ExecutiveProfileProps): ReactElement => {


  return (
    <CabinetPage
      pageName={'ExecutiveProfile'}
      noBorder
      headerContent={ 
        <Box display='flex' width='100%' justifyContent='space-between'
          sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Typography variant="h1">{`${leader.first_name} ${leader.last_name}`}</Typography>
          <Box display='flex' gap={2}>
            <CabIcon alt='Share' Icon={IoShareSocial} onClick={onClickShare} />
            <CabIcon alt='Edit' Icon={IoCreateOutline} onClick={onClickEdit} />
          </Box>
        </Box>
      }>
      <Box marginLeft={{xs: 2, sm: 5}} marginRight={{xs: 2, sm: 5}} height='100%'>
        <Grid container spacing={4} height='100%' rowGap={4}>
          <Grid item container xs={12} sm={12} md={7} spacing={4} height='100%'>
            <Grid item xs={12} height={'16%'} minHeight={170} sx={{ display: { xs: 'none', md: 'flex' }}}>
              <ExecutiveProfileHeader leader={leader} />
            </Grid>
            <Grid item xs={12} height={'16%'} minHeight={140} sx={{ display: { xs: 'flex', md: 'none' }}}>
              <ExecutiveProfileHeader leader={leader} noName />
            </Grid>
            <Grid item xs={12} height='42%'>
              <LeaderMeetingsWidget meetingsLoaded={meetingsLoaded} leaderId={leader.id} />
            </Grid>
            <Grid item xs={12} height='42%'>
              <LeaderRecurringMeetingsWidget meetingsLoaded={meetingsLoaded} leaderId={leader.id} />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} md={5} spacing={4} height='100%'>
            <Grid item xs={12} height={'16%'} minHeight={170} 
              sx={{ display: { xs: 'none', md: 'flex' }}}>
              <Box display='flex' width='100%' gap={1} alignItems='flex-start' justifyContent='flex-end'>
                <CabButton onClick={onClickShare}
                  icon={<CabIcon alt='Share' Icon={IoShareSocial} />}
                >
                  Share
                </CabButton>
                <CabButton buttonType='tertiary' onClick={onClickEdit} 
                  icon={<CabIcon alt='Edit' Icon={IoCreateOutline}/>}
                >
                  Edit
                </CabButton>
              </Box>
            </Grid>
            <Grid item xs={12} height={{xs: '50%', md:'42%'}}>
              <NotesWidget
                categories={[...categories, ...customCategories || []]}
                loading={!categoriesLoaded}
                onClickAddCategory={!leader.is_shared ? onClickAddCategory : undefined}
                locked={notesLocked}
              />
            </Grid>
            <Grid item xs={12} height={{xs: '50%', md:'42%'}}>
              <LeaderCard currentLeader={leader}/>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CabinetPage>
  );
};

export default ExecutiveProfile;