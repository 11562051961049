import { ReactElement } from "react";
import { Leader } from "../../store";
import { Box, Typography } from "@mui/material";
import colors from "../../colors";
import CabAvatar from "@CabComponents/CabAvatar";
import { getLeaderIconSrc } from "../../utils/leaderUtils";
import { CabToggleChip } from "@CabComponents/CabToggleChip";


interface ExecutiveProfileHeaderProps {
  leader: Leader;
  noName?: boolean;
}

const ExecutiveProfileHeader = ({leader, noName}:ExecutiveProfileHeaderProps): ReactElement => {


  return (
    <Box display='flex' border={`1px solid ${colors.black200}`} borderRadius='6px' 
      padding={2} width='100%' sx={{backgroundColor: colors.greyBackdrop}}>
      <Box display='flex' gap={2} width={'100%'} alignItems={'center'}>
        <CabAvatar
          key={leader.id}
          src={getLeaderIconSrc(leader)}
          color={leader.color}
          name={`${leader.first_name} ${leader.last_name}`}
          sx={{height: 65, width: 65, display: { xs: 'none', sm: 'flex' }}}
        />
        <Box display='flex' flexDirection='column' width={'100%'}>
          {!noName && (
            <Box display='flex' justifyContent='space-between' gap={1} alignItems={'center'}>
              <Typography variant='h1' fontSize={38} lineHeight={'50px'}>
                {`${leader.first_name} ${leader.last_name}`}
              </Typography>
              {leader.is_shared && (
                <CabToggleChip
                  title='Shared'
                  label='Shared'
                  sx={{backgroundColor: colors.black200, borderColor: colors.black200, height: 30, fontWeight: 500}}
                />
              )}
            </Box>
          )}
          <Typography variant='body1' sx={{color: colors.black700}}>{leader.email}</Typography>
          <Box display='flex' gap={.5}>
            <Typography variant='body1' sx={{color: colors.black700}}>{leader.role}</Typography>
            {leader.role && leader.company && (
              <Typography variant='body1' sx={{color: colors.black700}}>{`@ ${leader.company}`}</Typography>
            )}
          </Box>
          {leader.company && !leader.role && (
            <Typography variant='body1' sx={{color: colors.black700}}>{leader.company}</Typography>
          )}
          <Typography variant='body1' sx={{color: colors.black700}}>{leader.phone_number}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ExecutiveProfileHeader;